import { useRoute, createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import uniq from 'lodash/uniq';
import { computed, ref, watch } from 'vue';

// 【默认后台路由】
const modules = import.meta.globEager('./modules-mp/**/*.ts');
// 路由暂存
const routeModuleList: Array<RouteRecordRaw> = [];

Object.keys(modules).forEach((key) => {
  // console.log('1.Loading module:', key); // 调试信息
  const mod = modules[key].default || {};
  // console.log('1.Module content:', mod); // 调试信息
  const modList = Array.isArray(mod) ? [...mod] : [mod];
  routeModuleList.push(...modList);
});

// 【自定义后台路由】
const modulesBackend = import.meta.globEager('./modules-wx/**/*.ts');
// 路由暂存
const routeModuleListBackend: Array<RouteRecordRaw> = [];

Object.keys(modulesBackend).forEach((key) => {
  // console.log('2.Loading module:', key); // 调试信息
  const mod = modulesBackend[key].default || {};
  // console.log('2.Module content:', mod); // 调试信息
  const modList = Array.isArray(mod) ? [...mod] : [mod];
  routeModuleListBackend.push(...modList);
});

// 关于单层路由，meta 中设置 { single: true } 即可为单层路由，{ hidden: true } 即可在侧边栏隐藏该路由

// 存放动态路由
export const asyncRouterList: Array<RouteRecordRaw> = [...routeModuleList];

export const asyncRouterListBackend: Array<RouteRecordRaw> = [...routeModuleListBackend];

// 存放固定的路由
const defaultRouterList: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    // component: () => import('@/pages-mp/login/index.vue'),
    component: () => import('@/pages-www/login/index.vue'),
  },
  {
    path: '/authorize',
    name: 'authorize',
    component: () => import('@/pages-wx/auth/authPageManage/authPage.vue'),
  },
  {
    path: '/authorizeH5',
    name: 'authorizeH5',
    component: () => import('@/pages-wx/auth/authPageManage/H5.vue'),
  },
  {
    path: '/redirectPage',
    name: 'redirectPage',
    component: () => import('@/pages-wx/auth/authPageManage/redirectPage.vue'),
  },
  {
    path: '/',
    // redirect: '/dashboard/base',
    name: 'HomePage',
    component: () => import('@/pages-www/home/index.vue'),
  },
  {
    path: '/mp/:w+',
    name: 'mp404Page',
    redirect: '/mp/result/404',
  },
  {
    path: '/wx/:w+',
    name: 'wx404Page',
    redirect: '/wx/result/404',
  },
];

export const allRoutes = [...defaultRouterList, ...asyncRouterList, ...asyncRouterListBackend];

export const getRoutesExpanded = () => {
  const expandedRoutes = [];

  function processRoutes(routes, parentPath = '') {
    routes.forEach((item) => {
      const fullPath = parentPath ? `${parentPath}/${item.path}` : item.path;
      if (item.meta && item.meta.expanded) {
        expandedRoutes.push(fullPath);
      }

      if (item.children && item.children.length > 0) {
        processRoutes(item.children, fullPath);
      }
    });
  }

  processRoutes(allRoutes);

  return uniq(expandedRoutes);
};

export const getActive = (maxLevel = 3): string => {
  const route = useRoute();
  if (!route || !route.path) {
    return '';
  }
  const pathParts = route.path.split('/');
  const filteredParts = pathParts
    .filter((_item: string, index: number) => index <= maxLevel && index > 0)
    .map((item: string) => `/${item}`);
  const activePath = filteredParts.join('');
  // console.log('Active path:', activePath); // 调试信息
  return activePath;
};

// 导航判断当前页面状态src\layouts\components\MenuContent.vue
export const useActivePath = (maxLevel = 3) => {
  const route = useRoute();
  const activePath = ref('');

  const updateActivePath = () => {
    if (!route || !route.path) {
      console.warn('Route or route.path is undefined or empty');
      activePath.value = '';
      return;
    }

    const pathParts = route.path.split('/');
    // console.log('Path parts:', pathParts); // 调试信息

    const filteredParts = pathParts
      .filter((_item: string, index: number) => index <= maxLevel && index > 0)
      .map((item: string) => `/${item}`);

    activePath.value = filteredParts.join('');
    // console.log('Active path:', activePath.value); // 调试信息
  };

  watch(route, updateActivePath, { immediate: true });

  return computed(() => activePath.value);
};

const router = createRouter({
  history: createWebHistory(),
  routes: allRoutes,
  // linkActiveClass: 't-is-active', // 指定激活状态的类名
  scrollBehavior() {
    return {
      el: '#app',
      top: 0,
      behavior: 'smooth',
    };
  },
});

export default router;
